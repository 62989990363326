import React from "react";

export default function ConsentAgreement() {
  return (
    <div className="mx-3 mt-7" id="informedconsent">
      <h1>
        <strong>SwyftDoc Informed Consent Agreement</strong>
      </h1>
      <p class="last-revision">Last revision: March 11, 2019</p>

      <h2>Description of Services.</h2>
      <p>
        The Healthcare Professionals at SwyftDoc Professionals* provide
        healthcare services using interactive audio and video technologies where
        you and the Healthcare Professional are not in the same physical
        location. You can form an ongoing treatment relationship with a
        Healthcare Professional, however, your initial visit may begin as a
        consultation (e.g. to determine the most appropriate treatment setting
        for you to receive care) and will not necessarily give rise to an
        ongoing treatment relationship. You should seek emergency help or
        follow-up care when recommended by a Healthcare Professional or when
        otherwise needed, and continue to consult with your other healthcare
        providers as recommended. Among the benefits of our Services are
        improved access to healthcare and convenience. However, as with any
        health service, there are potential risks associated with the use of
        technology.
      </p>

      <h3>These risks include, but may not be limited to:</h3>
      <ul>
        <li>
          In rare cases, information transmitted may be insufficient for
          healthcare decision making.
        </li>
        <li>
          Disruptions can occur due to failures of the electronic equipment or
          internet connection. If this happens, you may be contacted by phone or
          other means of communication.
        </li>
        <li>
          In rare cases, a lack of access to all of your health records may
          result in adverse drug interactions or other errors.
        </li>
        <li>
          Although we incorporate strong security protocols to protect the
          confidentiality of your health information, in rare instances security
          protocols can fail, causing a breach of confidentiality.
        </li>
      </ul>

      <h3>
        By accepting this consent you acknowledge that you understand and agree
        with the following:
      </h3>
      <ul>
        <li>
          We may share information about the health care services you receive
          from us with your health insurance company. This may include
          information relating to genetic testing, substance abuse, mental
          health, communicable diseases and other health conditions, subject to
          the terms in our Privacy Policy.
        </li>
        <li>
          If your health insurance coverage does not pay for your Services
          charges in full, you may be fully or partially responsible for
          payment.
        </li>
        <li>
          Our Health Professionals may share your health records with other
          health care providers for purposes relating to the provision,
          coordination or management of your healthcare so that we can meet your
          healthcare needs. This may include information relating to genetic
          tests, substance abuse, mental health, communicable diseases and other
          health conditions, subject to the terms in the SwyftDoc
          Privacy Policy.
        </li>
        <li>
          We may determine that our clinical services are not appropriate for
          some or all of your treatment needs and may elect not to provide
          clinical services to you through the Site.
        </li>
        <li>
          With respect to psychotherapy, you are entitled to receive information
          from your Healthcare Professional about the methods of therapy, the
          techniques used, the duration of your therapy (if known), and the fee
          structure. You can seek a second opinion from another therapist or
          terminate therapy at any time.
        </li>
        <li>
          You attest that you have legal authority to act as guardian or
          personal representative of all children registered under your Account.
        </li>
        <li>No results can be guaranteed or assured.</li>
      </ul>

      <h2>Group Therapy.</h2>
      <p>
        If you and a Healthcare Professional decide to engage in group or
        couples therapy (collectively “<strong>Group Therapy</strong>”), you
        understand that information discussed in Group Therapy is for
        therapeutic purposes and is not intended for use in any legal
        proceedings involving Group Therapy participants. You agree not to
        subpoena the Healthcare Professional to testify for or against other
        Group Therapy participants or provide records in court actions against
        other Group Therapy participants. You understand that anything any Group
        Therapy participant tells the Healthcare Professional individually,
        whether on the phone or otherwise, may at the therapist’s discretion be
        shared with the other Group Therapy participants. You agree to share
        responsibility with the Healthcare Professional for the therapy process,
        including goal setting and termination.{" "}
      </p>

      <h2>Messaging.</h2>
      <p>
        You can send messages to a Healthcare Professional by contacting our
        Member Support team at 1-800-997-6196. Emails or electronic messages to
        Member Support or a Healthcare Professional may not be returned
        immediately. If you are experiencing a medical emergency, you should
        call 911 or go to the nearest emergency room.
      </p>

      <p>
        <i>
          *SwyftDoc Healthcare Professionals practice within a group of
          independently owned professional practices collectively known as
          “SwyftDoc Professionals”.
        </i>
      </p>
    </div>
  );
}
